import { userService } from "@/services/user.service";
import router from "@/router";

const user = JSON.parse(localStorage.getItem("user"));
const state = user
  ? { status: { loggedIn: true }, user, form: { loading: false, error: null } }
  : { status: {}, user: null, form: { loading: false, error: null } };

const actions = {
  login({ dispatch, commit }, { username, password, redirect }) {
    // Clear previous alert, if any
    dispatch("alert/clear", null, { root: true });
    commit("loginRequest", { username });

    userService.login(username, password).then(
      (user) => {
        commit("loginSuccess", user);
        // Redirect to previous location or home
        console.log(user.user.is_affiliato);
        if(user.user.is_affiliato){
          router.push({ name: redirect || "home" });
        }else if(user.user.is_famiglia){
          router.push({ name: redirect || "service_requests" });
        }
       
      },
      (error) => {
        commit("loginFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  logout({ commit }) {
    userService.logout();
    commit("logout");
    router.push({ name: "login" });
  },
  register({ dispatch, commit }, user) {
    commit("registerRequest", user);

    userService.register(user).then(
      (user) => {
        commit("registerSuccess", user);
        // router.push({ name: "login" });
        /* setTimeout(() => {
          // display success message after route change completes
          dispatch("alert/success", i18n.t("register.completed_title"), {
            root: true,
          });
        }); */
      },
      (error) => {
        commit("registerFailure", error);
        // @todo: For 400 errors, this should not be shown
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  update({ commit }, user) {
    commit("updateRequest", user);

    userService.update(user).then(
      // eslint-disable-next-line no-unused-vars
      (user) => commit("updateSuccess", user),
      (error) => commit("updateFailure", error)
    );
  },
  updateServiceParameters({ commit }, user) {
    commit("updateRequest", user);

    userService.updateServiceParameters(user).then(
      // eslint-disable-next-line no-unused-vars
      (user) => commit("updateSuccess", user),
      (error) => commit("updateFailure", error)
    );
  },
  // eslint-disable-next-line no-unused-vars
  clearRegistration({ _dispatch, commit }) {
    commit("clearRegistration");
  },
  sendContactForm({ dispatch, commit }, data) {
    // Clear previous alert, if any
    dispatch("alert/clear", null, { root: true });
    commit("sendContactFormRequest", data);

    userService.sendContactForm(data).then(
      (response) => {
        commit("sendContactFormSuccess", response);
        dispatch("alert/success", "Richiesta di contatto inviata", {
          root: true,
        });
      },
      (error) => {
        commit("sendContactFormFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
};

const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggingIn: false, loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = { loggingIn: false, loggedIn: false };
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
  // eslint-disable-next-line no-unused-vars
  registerRequest(state, _user) {
    state.status = { registering: true, registered: false };
  },
  // eslint-disable-next-line no-unused-vars
  registerSuccess(state, _user) {
    state.status = { registering: false, registered: true };
  },
  // eslint-disable-next-line no-unused-vars
  registerFailure(state, error) {
    state.status = { registering: false, registered: false, formErrors: error };
  },
  clearRegistration(state) {
    state.status = { registering: false, registered: false, formErrors: null };
  },
  // eslint-disable-next-line no-unused-vars
  sendContactFormRequest(state, _data) {
    state.form = { loading: true, error: null };
  },
  // eslint-disable-next-line no-unused-vars
  sendContactFormSuccess(state, _response) {
    state.form = { loading: false, error: null };
  },
  // eslint-disable-next-line no-unused-vars
  sendContactFormFailure(state, error) {
    state.form = { loading: false, error: error };
  },
  updateRequest(state) {
    state.form = { loading: true, error: null };
  },
  updateSuccess(state, partialUser) {
    state.form = { loading: false };
    user= { ...user, ...partialUser };
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },
  updateFailure(state, error) {
    state.form = { loading: false, error: error };
  },
};

const getters = {
  loading(state) {
    return state.status.loggingIn || state.status.registering || false;
  },
  registered(state) {
    return state.status.registered || false;
  },
  getFormErrors(state) {
    return state.status.formErrors || null;
  },
  sendingContactForm(state) {
    return state.form.loading;
  },
  contactFormErrors(state) {
    return state.form.error || null;
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
