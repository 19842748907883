var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "fluid-container footer bg-primary" }, [
    _c("div", { staticClass: "row ml-4 mr-4" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "col-lg-4" },
        [
          _c(
            "b-button",
            {
              staticClass: "buttonCustom",
              attrs: { block: "", variant: "secondary" },
              on: {
                click: function($event) {
                  _vm.mostraDropdown2 = !_vm.mostraDropdown2
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("footer.phone")) + " ")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mostraDropdown2,
                  expression: "mostraDropdown2"
                }
              ],
              staticClass: "dropdown-content"
            },
            [_vm._v(" " + _vm._s(_vm.$t("footer_value.numero_contatto")) + " ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "buttonCustom",
              attrs: { block: "", variant: "secondary" },
              on: {
                click: function($event) {
                  _vm.mostraDropdown3 = !_vm.mostraDropdown3
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("footer.email")) + " ")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mostraDropdown3,
                  expression: "mostraDropdown3"
                }
              ],
              staticClass: "dropdown-content"
            },
            [_vm._v(" " + _vm._s(_vm.$t("footer_value.email")) + " ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "buttonCustom",
              attrs: { block: "", variant: "secondary" },
              on: {
                click: function($event) {
                  _vm.mostraDropdown4 = !_vm.mostraDropdown4
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("footer.address")))]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mostraDropdown4,
                  expression: "mostraDropdown4"
                }
              ],
              staticClass: "dropdown-content"
            },
            [_vm._v(" " + _vm._s(_vm.$t("footer_value.street")) + " ")]
          )
        ],
        1
      )
    ]),
    _c("p", { staticClass: "text-center mt-3" }, [
      _vm._v(" Copyright © 2022 My Care. All Rights Reserved. ")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-4 text-center" }, [
      _c("img", {
        staticClass: "rounded float-left",
        attrs: {
          src: require("@/assets/images/loghetto.png"),
          height: "100px",
          width: "auto",
          alt: "Logo Footer"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }