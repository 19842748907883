<template>
  <div id="app">
    <div class="p-0 m-0 container-fluid">
      <Navbar :key="this.$isLogged()" />
      <div class="content">
        <b-alert
          v-if="alert.message"
          :variant="alert.type"
          :show="true"
          dismissible
          fade
          class="m-2"
        >
          {{ alert.message }}
        </b-alert>
        <router-view />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import i18n from "@/i18n";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  components: { Navbar, Footer },
  metaInfo: {
    title: i18n.t("app_name") + " | ",
    // titleTemplate: "%s | Home",
    meta: [{ name: "description", content: i18n.t("app_name") }],
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      clearRegistration: "account/clearRegistration",
    }),
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearAlert();
      // clear already registered status
      this.clearRegistration();
    },
  },
};
</script>

<style scoped lang="scss">
@import "/assets/css/styles.scss";
.content {
  min-height: calc(100vh - #{$footer-height} - #{$header-height});
}
</style>
