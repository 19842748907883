import { authHeader } from "@/helpers";
import { endpoints } from "./endpoints";

export function test(userId) {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    // Modify the API endpoint to accept the user ID
    return fetch(
      `${endpoints.apiUrl}${endpoints.servicesAffiliateUrl}/${userId}/`,
      requestOptions
    ).then(handleResponse);
  };


export const serviceService = {
  getAll,
  getById,
  getAffiliatesByCityId,
  getPriceByServiceAndAffiliate,
  postPriceByServiceAndAffiliate,
  update,
  updatePaid,
  delete: _delete,
};

function postPriceByServiceAndAffiliate(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.priceByServiceAndAffiliateUrl}`,
    requestOptions
  ).then(handleResponse);
}


function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.servicesUrl}`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.servicesUrl}${id}`,
    requestOptions
  ).then(handleResponse);
}

function update(service) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(service),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.serviceUpdateUrl.replace(
      "{id}",
      service.id
    )}`,
    requestOptions
  ).then(handleResponse);
}

function updatePaid(service) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(service),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.requestServicePaidUpdateUrl.replace(
      "{id}",
      service.id
    )}`,
    requestOptions
  ).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.serviceDeleteUrl.replace("{id}", id)}`,
    requestOptions
  ).then(handleResponse);
}

function getAffiliatesByCityId(cityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.affiliateByCityUrl.replace(
      "{cityId}",
      cityId
    )}`,
    requestOptions
  ).then(handleResponse);
}

function getPriceByServiceAndAffiliate(
  serviceId,
  affiliateId,
  endDate,
  startDate,
  days
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.priceByServiceAndAffiliateUrl
      .replace("{serviceId}", serviceId)
      .replace("{affiliateId}", affiliateId)
      .replace("{endDate}", endDate)
      .replace("{startDate}", startDate)
      .replace("{days}", days)}`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload();
      }

      const error =
        data && data.non_field_errors
          ? data.non_field_errors.toString()
          : response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
