import { marginiService } from "@/services/marginiServizi.service";

const state = {
  margini: { loading: false, items: [], error: null },
};

const actions = {
  async getMargini({ commit }) {
    commit("getMarginiRequest");

    try {
      const response = await marginiService.getServiceMarginiServizi();
      const margini = response; // Assumiamo che i dati siano nel campo 'data' della risposta
      commit("getMarginiSuccess", margini);
    } catch (error) {
      commit("getMarginiFailure", error);
    }
  },

  updateMarginiServizi({ dispatch, commit }, data) {
    // Clear previous alert, if any
    dispatch("alert/clear", null, { root: true });
    commit("requestMarginiRequest");

    marginiService.updateMarg(data).then(
      (serviceRequested) => {
        commit("requestMarginiSuccess", serviceRequested);
      },
      (error) => {
        commit("requestMarginiFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
};

const getters = {
  loadingMargini(state) {
    return state.margini.loading || false;
  },
  margini: (state) => {
    return state.margini || [];
  },
  loadingMarginiRequested: (state) => {
    return state.marginiRequested.loading || false;
  },
  marginiRequested: (state) => {
    return state.marginiRequested.request;
  },
};

const mutations = {
  getMarginiRequest(state) {
    state.loading = true;
  },
  getMarginiSuccess(state, margini) {
    state.margini = margini;
  },
  getMarginiFailure(state, error) {
    state.margini.error = error;
  },
  requestMarginiRequest(state) {
    state.loading = true;
  },
  requestMarginiSuccess(state, marginiRequested) {
    state.request = marginiRequested;
    state.loading = false;
  },
  requestMarginiFailure(state, error) {
    state.error = error;
    state.loading = false;
  },
};

export const margini = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
