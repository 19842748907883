import { cartService } from "@/services/cart.service";
import router from "@/router";

const state = {
  all: {},
};

const actions = {
  get({ commit }) {
    commit("getRequest");

    cartService.get().then(
      (cart) => commit("getSuccess", cart),
      (error) => commit("getFailure", error)
    );
  },

  addItem({ commit }, service, quantity) {
    commit("addItemRequest");

    cartService.addItem(service, quantity).then(
      (cart) => commit("addItemSuccess", cart),
      (error) => commit("addItemFailure", error)
    );
  },

  updateItem({ commit }, cartItemId, quantity) {
    commit("updateItemRequest");

    cartService.updateItem(cartItemId, quantity).then(
      (message) => commit("updateItemSuccess", message),
      (error) => commit("updateItemFailure", error)
    );
  },

  delete({ commit }, cartItemId) {
    commit("deleteRequest", cartItemId);

    cartService.delete(cartItemId).then(
      (cart) => commit("deleteSuccess", cart),
      (error) =>
        commit("deleteFailure", { cartItemId, error: error.toString() })
    );
  },
};

const mutations = {
  getRequest(state) {
    state.all = { loading: true };
  },
  getSuccess(state, cart) {
    localStorage.setItem("cart", JSON.stringify(cart));
    state.all = { cart: cart };
  },
  getFailure(state, error) {
    state.all = { error };
  },
  addItemRequest(state) {
    state.all = { loading: true };
  },
  addItemSuccess(state, cart) {
    localStorage.setItem("cart", JSON.stringify(cart));
    state.all = { cart: cart };
    router.push({ name: "cart" });
  },
  addItemFailure(state, error) {
    state.all = { error };
  },
  updateItemRequest(state) {
    state.all = { loading: true };
  },
  // eslint-disable-next-line no-unused-vars
  updateItemSuccess(state, message) {
    // @todo: Update cart item in state
    // state.all = { cart: cart };
  },
  updateItemFailure(state, error) {
    state.all = { error };
  },
  deleteRequest(state) {
    // add 'deleting:true' property to cart being deleted
    state.all.cart = { ...state.all.cart, deleting: true };
  },
  deleteSuccess(state, cart) {
    localStorage.setItem("cart", JSON.stringify(cart));
    state.all = { cart: cart };
  },
  deleteFailure(state, error) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    // eslint-disable-next-line no-unused-vars
    const { deleting, ...cartCopy } = state.all.cart;
    state.all.cart = { ...cartCopy, deleteError: error };
  },
};

const getters = {
  loading(state) {
    return state.all.loading || false;
  },
  getCart: (state) => {
    return state.all.cart || null;
  },
  getCartTotal: (state) => {
    return state.all.cart && state.all.cart.length
      ? state.all.cart[0].cart.total_price
      : 0;
  },
};

export const cart = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
