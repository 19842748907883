export const endpoints = {
  mock: process.env.VUE_APP_MOCK_HTTP === "true",
  apiUrl: process.env.VUE_APP_API_URL || "http://195.231.25.230:8000", //"http://85.235.129.114:8000", //, 
  loginUrl: "/login/",
  contactFormUrl: "/api/contactForm/",
  citiesUrl: "/api/city/",
  provincesUrl: "/api/provinces/",
  countriesUrl: "/api/countries/",
  registerAffiliateUrl: "/api/registration/affiliato/",
  registerCaretakerUrl: "/api/registration/badante/",
  registerCaregiverUrl: "/api/registration/famiglia/",
  servicesUrl: "/api/servizi/",
  servicesAffiliateUrl:"/api/serviziperaffiliato",
  serviceUpdateUrl: "/api/servizi/{id}/update",
  serviceDeleteUrl: "/api/servizi/{id}/delete",
  // affiliateByCityUrl: "/api/affiliatesProvince/{cityId}/",
  affiliateByCityUrl: "/api/affiliatesRegion/{cityId}/",
  priceByServiceAndAffiliateUrl: "/api/services/priceCalculate/",
  requestServiceUserUrl: "/api/myOrder/",
  requestServiceUrl: "/api/richiedi_servizio/",
  requestMarginiServiziUrl: "/api/marginiservizi/{id}/",
  updateMarginiServiziUrl: "/api/marginiservizi/update/{id}/",
  requestServiceUpdateUrl: "/api/richiedi_servizio/{id}/update/",
  requestServicePaidUpdateUrl: "/api/richiedi_servizio_pagato/{id}/update/",
  caretakersByCityUrl: "/api/caretakerProvince/{cityId}/",
  caretakersUrl: "api/badante/",
  sendInvoiceUrl: "/api/invoice/{id}/",
  profileUpdateUrl: "/api/{type}/{id}/update/",
  servicesParametersUpdateUrl: "/api/affiliateMarginiUpdate/{id}/",
  affiliateMarginiUrl: "/api/affiliateMargini/{id}/",
  cartUrl: "/api/cart/",
  question: "/api/question/",
  familyUrl: "/api/famiglia/",
};

export const fields = {
  serviceAffiliatePrice: "price",
};

export const processStatuses = {
  notProcessed: 0,
  confirmed: 1,
  rejected: 2,
  paid: 3,
  completed: 4
};
