import { authHeader } from "@/helpers";
import { endpoints } from "./endpoints";

export const serviceRequestService = {
  getServiceRequests,
  getServiceRequestById,
  update,
  updatePaid,
  getCaretakersByCity,
  sendInvoice,
  requestService,
  question,
};

function getServiceRequests(isRegularUser) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const endpoint =
    isRegularUser === true
      ? endpoints.requestServiceUserUrl
      : endpoints.requestServiceUrl;

  return fetch(`${endpoints.apiUrl}${endpoint}`, requestOptions).then(
    handleResponse
  );
}

function getServiceRequestById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.requestServiceUrl}${id}/`,
    requestOptions
  ).then(handleResponse);
}

function update(serviceRequest) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(serviceRequest),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.requestServiceUpdateUrl.replace(
      "{id}",
      serviceRequest.id
    )}`,
    requestOptions
  ).then(handleResponse);
}

function updatePaid(serviceRequest) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(serviceRequest),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.requestServicePaidUpdateUrl.replace(
      "{id}",
      serviceRequest.id
    )}`,
    requestOptions
  ).then(handleResponse);
}

function getCaretakersByCity(cityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.caretakersByCityUrl.replace(
      "{cityId}",
      cityId
    )}`,
    requestOptions
  ).then(handleResponse);
}

function sendInvoice(serviceRequest) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.sendInvoiceUrl.replace(
      "{id}",
      serviceRequest.id
    )}`,
    requestOptions
  ).then(handleResponse);
}

function requestService(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.requestServiceUrl}`,
    requestOptions
  ).then(handleResponse);
}

function question(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${endpoints.apiUrl}${endpoints.question}`, requestOptions).then(
    handleResponse
  );
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload();
      }

      const error =
        data && data.non_field_errors
          ? data.non_field_errors.toString()
          : response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
