<template>
  <footer class="fluid-container footer bg-primary">
    <div class="row ml-4 mr-4">
      <div class="col-lg-4 text-center">
        <img
          src="@/assets/images/loghetto.png"
          height="100px"
          width="auto"
          alt="Logo Footer"
          class="rounded float-left"
        />
      </div>
    <!-- </div>
    <div class="row ml-4 mr-4"> -->
      <div class="col-lg-4">
        <!-- <b-button block variant="secondary" class="buttonCustom" v-on:click="mostraDropdown1 = !mostraDropdown1">{{ $t("footer.contacts") }} </b-button>
        <div v-show="mostraDropdown1" class="dropdown-content">Contatttaaaa</div> -->
        <b-button
          block
          variant="secondary"
          class="buttonCustom"
          v-on:click="mostraDropdown2 = !mostraDropdown2"
          >{{ $t("footer.phone") }}
        </b-button>
        <div v-show="mostraDropdown2" class="dropdown-content">
          {{ $t("footer_value.numero_contatto") }}
        </div>
        <b-button
          block
          variant="secondary"
          class="buttonCustom"
          v-on:click="mostraDropdown3 = !mostraDropdown3"
          >{{ $t("footer.email") }}
        </b-button>
        <div v-show="mostraDropdown3" class="dropdown-content">
          {{ $t("footer_value.email") }}
        </div>
        <b-button
          block
          variant="secondary"
          class="buttonCustom"
          v-on:click="mostraDropdown4 = !mostraDropdown4"
          >{{ $t("footer.address") }}</b-button
        >
        <div v-show="mostraDropdown4" class="dropdown-content">
          {{ $t("footer_value.street") }}
        </div>
      </div>
      <!-- <div class="col-lg-8 text-right">
        <div class="mapouter">
          <div class="gmap_canvas">
            <iframe
              width="600"
              height="200"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=via+toledo+100+napoli&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>
      </div> -->
    </div>

    <p class="text-center mt-3">
      Copyright &copy; 2022 My Care. All Rights Reserved.
    </p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      markers: [],
      // mostraDropdown1: false,
      mostraDropdown2: false,
      mostraDropdown3: false,
      mostraDropdown4: false,
      mostraDropdown5: false,
    };
  },
};
</script>

<style>
iframe {
  max-width: 100%;
}
.fluid-container.footer {
  padding: 1rem;
  color: #fff;
  margin-bottom: 0;
}

.buttonCustom {
  margin-top: 10px;
}

.dropdown-content {
  color: black;
  background-color: white;
  text-align: center;
}

.fluid-container.footer > *:last-child {
}
</style>

