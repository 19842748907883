var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "bg-white",
      attrs: { toggleable: "lg", type: "light", sticky: "" }
    },
    [
      _c("b-navbar-brand", { attrs: { to: { name: _vm.goToHome() } } }, [
        _c("img", {
          staticClass: "d-inline-block align-top",
          attrs: {
            src: require("@/assets/images/logo.png"),
            alt: _vm.$t("app_name"),
            height: "66px",
            width: "auto"
          }
        })
      ]),
      _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
      _c(
        "b-collapse",
        { attrs: { id: "nav-collapse", "is-nav": "" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c(
                "b-nav-item",
                {
                  attrs: {
                    to: { name: "about" },
                    active: _vm.$route.name === "about"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("menu.about")) + " ")]
              ),
              _c(
                "b-nav-item",
                {
                  attrs: {
                    to: { name: "Condizioni Generali" },
                    active: _vm.$route.name === "Condizioni Generali"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("menu.Condizioni Generali")) + " ")]
              ),
              _c(
                "b-nav-item",
                {
                  attrs: {
                    to: { name: "tech-fast" },
                    active: _vm.$route.name === "tech-fast"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("menu.tech_fast")) + " ")]
              ),
              _vm.$isLogged() && _vm.$loggedUser().is_affiliato
                ? _c(
                    "b-nav-item",
                    {
                      attrs: {
                        to: { name: "services" },
                        active: _vm.$route.name === "services"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("menu.services")) + " ")]
                  )
                : _vm._e(),
              _vm.$isLogged() && _vm.$loggedUser().is_affiliato
                ? _c(
                    "b-nav-item",
                    {
                      attrs: {
                        to: { name: "request_service_affiliate" },
                        active: _vm.$route.name === "request_service_affiliate"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("menu.request_service_affiliate")) + " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.$isLogged()
                ? _c(
                    "b-nav-item",
                    {
                      attrs: {
                        to: { name: "service_requests" },
                        active:
                          _vm.$route.name === "service_requests" ||
                          _vm.$route.name === "payment"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("menu.service_requests")) + " ")]
                  )
                : _vm._e(),
              !_vm.$isLogged()
                ? _c(
                    "b-nav-item",
                    {
                      attrs: {
                        to: { name: "login" },
                        active: _vm.$route.name === "login"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("menu.login")) + " ")]
                  )
                : _vm._e(),
              _c(
                "b-nav-item",
                {
                  attrs: {
                    to: { name: "prodottiar" },
                    active: _vm.$route.name === "prodottiar"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("menu.prodottiar")) + " ")]
              ),
              _vm.i18n_enabled
                ? _c(
                    "b-nav-item-dropdown",
                    { attrs: { text: _vm.$t("menu.language"), right: "" } },
                    _vm._l(_vm.languages, function(lang, i) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: "lang-" + i,
                          attrs: {
                            disabled: lang === _vm.$i18n.locale,
                            value: lang
                          },
                          on: {
                            click: function($event) {
                              return _vm.changeLocale(lang)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(lang.toUpperCase()) + " ")]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.$loggedUser()
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function() {
                              return [
                                _vm.$loggedUser()
                                  ? _c("em", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$loggedUser().first_name +
                                            " " +
                                            _vm.$loggedUser().last_name
                                        )
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1040140196
                      )
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: { name: "profile" } } },
                        [_vm._v(_vm._s(_vm.$t("menu.profile")))]
                      ),
                      _vm.$isLogged() && _vm.$loggedUser().is_affiliato
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: { name: "manage_services" } } },
                            [_vm._v(_vm._s(_vm.$t("menu.manage_services")))]
                          )
                        : _vm._e(),
                      _vm.$isLogged() && _vm.$loggedUser().is_affiliato
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: { name: "register" } } },
                            [_vm._v(_vm._s(_vm.$t("menu.register")))]
                          )
                        : _vm._e(),
                      _c("b-dropdown-item", { on: { click: this.logout } }, [
                        _vm._v(_vm._s(_vm.$t("menu.logout")))
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }