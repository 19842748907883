
import { serviceRequestService } from "@/services";
import i18n from "@/i18n";

const state = {
  all: { loading: false, items: [], errors: null },
  serviceRequested: { loading: false, request: null },
  caretakersByCity: { loading: false, items: [], errors: null },
};

const actions = {
  getServiceRequests({ commit }, isAffiliate) {
    commit("getServiceRequestsRequest");

    serviceRequestService.getServiceRequests(isAffiliate).then(
      (services) => commit("getServiceRequestsSuccess", services),
      (error) => commit("getServiceRequestsFailure", error)
    );
  },

  getServiceRequestById({ commit }, id) {
    commit("requestServiceRequest", id);

    serviceRequestService.getServiceRequestById(id).then(
      (service) => commit("requestServiceSuccess", service),
      (error) =>
        commit("requestServiceFailure", { id, error: error.toString() })
    );
  },
  requestService({ dispatch, commit }, data) {
    // Clear previous alert, if any
    dispatch("alert/clear", null, { root: true });
    commit("requestServiceRequest");

    serviceRequestService.requestService(data).then(
      (serviceRequested) => {
        commit("requestServiceSuccess", serviceRequested);
        dispatch("alert/success", i18n.t("messages.service_request"), {
          root: true,
        });
      },
      (error) => {
        commit("requestServiceFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  updateServiceRequest({ dispatch, commit }, data) {
    dispatch("alert/clear", null, { root: true });
    commit("requestServiceRequest");

    serviceRequestService.update(data).then(
      (serviceRequested) => {
        commit("requestServiceSuccess", serviceRequested);
        dispatch("alert/success", i18n.t("messages.service_request"), {
          root: true,
        });
      },
      (error) => {
        commit("requestServiceFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  updateServicePaidRequest({ dispatch, commit }, data) {
    console.log(data);
    // Clear previous alert, if any
    dispatch("alert/clear", null, { root: true });
    commit("requestServiceRequest");

    serviceRequestService.updatePaid(data).then(
      (serviceRequested) => {
        commit("requestServiceSuccess", serviceRequested);
      },
      (error) => {
        commit("requestServiceFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  getCaretakersByCity({ commit }, cityId) {
    commit("getCaretakersByCityRequest");

    serviceRequestService.getCaretakersByCity(cityId).then(
      (caretakers) => commit("getCaretakersByCitySuccess", caretakers),
      (error) => commit("getCaretakersByCityFailure", error)
    );
  },

sendInvoice({ dispatch, commit }, data) {
  // Clear previous alert, if any
  dispatch("alert/clear", null, { root: true });
  commit("requestServiceRequest");

  serviceRequestService.sendInvoice(data).then(
    (serviceRequested) => {
      commit("requestServiceSuccess", serviceRequested);
    },
    (error) => {
      commit("requestServiceFailure", error);
      dispatch("alert/error", error, { root: true });
    }
  );
},

question({ dispatch, commit }, data) {
  // Clear previous alert, if any
  dispatch("alert/clear", null, { root: true });
  commit("requestServiceRequest");

  serviceRequestService.question(data).then(
    (serviceRequested) => {
      commit("requestServiceSuccess", serviceRequested);
    },
    (error) => {
      commit("requestServiceFailure", error);
      dispatch("alert/error", error, { root: true });
    }
  );
 },
};

const getters = {
  loading(state) {
    return state.all.loading || false;
  },
  serviceRequests: (state) => {
    return state.all.items || [];
  },
  loadingServiceRequested: (state) => {
    return state.serviceRequested.loading || false;
  },
  serviceRequested: (state) => {
    return state.serviceRequested.request;
  },
  loadingCaretakersByCity: (state) => {
    return state.caretakersByCity.loading || false;
  },
  caretakersByCity: (state) => {
    return state.caretakersByCity.items;
  },
};

const mutations = {
  getServiceRequestsRequest(state) {
    state.all.loading = true;
  },
  getServiceRequestsSuccess(state, serviceRequests) {
    state.all = { loading: false, items: serviceRequests, errors: null };
  },
  getServiceRequestsFailure(state, error) {
    state.all = { ...state.all, loading: false, error: error };
  },
  requestServiceRequest(state) {
    state.serviceRequested.loading = true;
  },
  requestServiceSuccess(state, serviceRequested) {
    state.serviceRequested.request = serviceRequested;
    state.serviceRequested.loading = false;
  },
  requestServiceFailure(state, error) {
    state.serviceRequested.error = error;
    state.serviceRequested.loading = false;
  },
  getCaretakersByCityRequest(state) {
    state.caretakersByCity.loading = true;
  },
  getCaretakersByCitySuccess(state, caretakers) {
    state.caretakersByCity = {
      loading: false,
      items: caretakers,
      errors: null,
    };
  },
  getCaretakersByCityFailure(state, error) {
    state.caretakersByCity.error = error;
    state.caretakersByCity.loading = false;
  },
};

export const serviceRequests = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};