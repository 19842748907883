import { authHeader } from "@/helpers";
import { endpoints } from "./endpoints";
import { userTypes } from "@/services/user.types";

export const userService = {
  login,
  logout,
  register,
  sendContactForm,
  getAll,
  getById,
  update,
  updateServiceParameters,
  delete: _delete,
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${endpoints.apiUrl}${endpoints.loginUrl}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // login successful if there's a jwt token in the response
      if (data.access) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("access_token", data.access);
        localStorage.setItem("refresh_token", data.refresh);
        if (data.user.is_affiliato) {
          data.user.type = userTypes.affiliate;
        } else {
          if (data.user.is_badante) {
            data.user.type = userTypes.caretaker;
          } else {
            if (data.user.is_famiglia) {
              data.user.type = userTypes.caregiver;
            } else {
              if (data.user.is_staff) {
                data.user.type = userTypes.staff;
              } else {
                throw "Cannot determine user type";
              }
            }
          }
        }
        localStorage.setItem("user", JSON.stringify(data.user));
        return data;
      } else {
        throw "Unable to parse response";
      }
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");
}

function register(user) {
  const isCareTaker = userTypes.caretaker === user.type;
  const formData = new FormData();
  let url = null;
  switch (user.type) {
    case userTypes.affiliate:
      url = endpoints.registerAffiliateUrl;
      break;
    case userTypes.caretaker:
      url = endpoints.registerCaretakerUrl;
      for (let key in user.availability) {
        for (let innerKey in user.availability[key]) {
          formData.append("availability", user.availability[key][innerKey]);
        }
      }
      for (let key in user) {
        if (key === "availability") {
          continue;
        }
        if (key === "servizi" || key === "working_provinces") {
          // formData.append(key, JSON.stringify(user[key]));
          for (let arrayKey in user[key]) {
            formData.append(key, user[key][arrayKey]);
          }
        } else {
          formData.append(key, user[key]);
        }
      }
      break;
    case userTypes.caregiver:
      url = endpoints.registerCaregiverUrl;
      break;
    default:
      throw `Unhandled type ${user.type}`;
  }

  const requestOptions = {
    method: "POST",
    headers: isCareTaker
      ? {}
      : {
          "Content-Type": "application/json",
        },
    body: isCareTaker ? formData : JSON.stringify(user),
  };

  return fetch(`${endpoints.apiUrl}${url}`, requestOptions).then(
    handleResponse
  );
}

function sendContactForm(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.contactFormUrl}`,
    requestOptions
  ).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${endpoints.apiUrl}/users`, requestOptions).then(
    handleResponse
  );
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${endpoints.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function update(user) {
  let type = null;
  let formData = JSON.stringify(user);
  let headers = { ...authHeader(), "Content-Type": "application/json" };
  switch (user.type) {
    case userTypes.affiliate:
      type = "affiliato";
      break;
    case userTypes.caretaker:
      type = "badante";
      formData = new FormData();
      for (let key in user.availability) {
        for (let innerKey in user.availability[key]) {
          formData.append("availability", user.availability[key][innerKey]);
        }
      }
      for (let key in user) {
        if (key === "availability") {
          continue;
        }
        if (key === "servizi" || key === "working_provinces") {
          // formData.append(key, JSON.stringify(user[key]));
          for (let arrayKey in user[key]) {
            formData.append(key, user[key][arrayKey]);
          }
        } else {
          formData.append(key, user[key]);
        }
      }
      headers = authHeader();
      break;
    case userTypes.caregiver:
      type = "famiglia";
      break;
  }
  const requestOptions = {
    method: "PUT",
    headers: headers,
    body: formData,
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.profileUpdateUrl
      .replace("{type}", type)
      .replace("{id}", user.id)}`,
    requestOptions
  ).then(handleResponse);
}

function updateServiceParameters(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  return fetch(
    `${endpoints.apiUrl}${endpoints.requestMarginiServiziUrl.replace(
      "{id}",
      user.id
    )}`,
    requestOptions
  ).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${endpoints.apiUrl}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload();
      }
      if (response.status === 400) {
        // validation errors
        return Promise.reject(data.non_field_errors || data);
      }

      const error =
        data && data.non_field_errors
          ? data.non_field_errors.toString()
          : response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
