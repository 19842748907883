import { cityService } from "@/services/city.service";

const state = {
  cities: { loading: false, items: [], error: null },
  provinces: { loading: false, items: [], error: null },
  countries: { loading: false, items: [], error: null },
};

const actions = {
  getCities({ commit }) {
    commit("getCitiesRequest");

    cityService.getCities().then(
      (cities) => commit("getCitiesSuccess", cities),
      (error) => commit("getCitiesFailure", error)
    );
  },
  getProvinces({ commit }) {
    commit("getProvincesRequest");

    cityService.getProvinces().then(
      (provinces) => commit("getProvincesSuccess", provinces),
      (error) => commit("getProvincesFailure", error)
    );
  },
  getCountries({ commit }) {
    commit("getCountriesRequest");

    cityService.getCountries().then(
      (countries) => commit("getCountriesSuccess", countries),
      (error) => commit("getCountriesFailure", error)
    );
  },
};

const getters = {
  loadingCities(state) {
    return state.cities.loading || false;
  },
  cities: (state) => {
    return state.cities.items || [];
  },
  loadingProvinces(state) {
    return state.provinces.loading || false;
  },
  provinces: (state) => {
    return state.provinces.items || [];
  },
  loadingCountries(state) {
    return state.countries.loading || false;
  },
  countries: (state) => {
    return state.countries.items || [];
  },
};

const mutations = {
  initStore(state) {
    const cities = localStorage.getItem("cities");
    if (cities) {
      state.cities.items = JSON.parse(cities);
    }
    const provinces = localStorage.getItem("provinces");
    if (provinces) {
      state.provinces.items = JSON.parse(provinces);
    }
    const countries = localStorage.getItem("countries");
    if (countries) {
      state.countries.items = JSON.parse(countries);
    }
  },
  getCitiesRequest(state) {
    state.cities.loading = true;
  },
  getCitiesSuccess(state, cities) {
    // Cache services into local storage
    localStorage.setItem("cities", JSON.stringify(cities));
    state.cities.items = cities;
  },
  getCitiesFailure(state, error) {
    state.cities.error = error;
  },
  getProvincesRequest(state) {
    state.provinces.loading = true;
  },
  getProvincesSuccess(state, provinces) {
    // Cache services into local storage
    localStorage.setItem("provinces", JSON.stringify(provinces));
    state.provinces.items = provinces;
  },
  getProvincesFailure(state, error) {
    state.provinces.error = error;
  },
  getCountriesRequest(state) {
    state.countries.loading = true;
  },
  getCountriesSuccess(state, countries) {
    // Cache services into local storage
    localStorage.setItem("countries", JSON.stringify(countries));
    state.countries.items = countries;
  },
  getCountriesFailure(state, error) {
    state.countries.error = error;
  },
};

export const cities = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
