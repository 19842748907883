import { serviceService } from "@/services/service.service";

const state = {
  all: { loading: false },
  service: { loading: false },
  affiliates: { loading: false, affiliates: [] },
  price: { loading: false, price: null },
};

const actions = {
  postPriceByServiceAndAffiliate({ dispatch, commit }, data) {
    // Clear previous alert, if any
    dispatch("alert/clear", null, { root: true });
    commit("createNewServiceRequest");

    serviceService.postPriceByServiceAndAffiliate(data).then(
      (response) => {
        commit("createNewServiceSuccess", response);
      },
      (error) => {
        commit("createNewServiceFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },


  getAll({ commit }) {
    commit("getAllRequest");

    serviceService.getAll().then(
      (services) => commit("getAllSuccess", services),
      (error) => commit("getAllFailure", error)
    );
  },

  getById({ commit }, id) {
    commit("getByIdRequest", id);

    serviceService.getById(id).then(
      (service) => commit("getByIdSuccess", service),
      (error) => commit("getByIdFailure", { id, error: error.toString() })
    );
  },

  getAffiliatesByCityId({ commit }, id) {
    commit("getAffiliatesByCityIdRequest", id);

    serviceService.getAffiliatesByCityId(id).then(
      (affiliates) => commit("getAffiliatesByCityIdSuccess", affiliates),
      (error) =>
        commit("getAffiliatesByCityIdFailure", { id, error: error.toString() })
    );
  },

  getPriceByServiceAndAffiliate(
    { commit },
    { service_id, affiliate_id, end_date, start_date, days }
  ) {
    commit(
      "getPriceByServiceAndAffiliateRequest",
      service_id,
      affiliate_id,
      end_date,
      start_date,
      days
    );

    return serviceService
      .getPriceByServiceAndAffiliate(
        service_id,
        affiliate_id,
        end_date,
        start_date,
        days
      )
      .then(
        (price) => {
          commit("getPriceByServiceAndAffiliateSuccess", price);
          return price;
        },
        (error) =>
          commit("getPriceByServiceAndAffiliateFailure", {
            service_id: service_id,
            affiliate_id: affiliate_id,
            error: error.toString(),
          })
      );
  },

  resetAffiliates({ commit }) {
    commit("resetAffiliates");
  },

  resetPrice({ commit }) {
    commit("resetPrice");
  },

  delete({ commit }, id) {
    commit("deleteRequest", id);

    serviceService.delete(id).then(
      // eslint-disable-next-line no-unused-vars
      (_service) => commit("deleteSuccess", id),
      (error) => commit("deleteFailure", { id, error: error.toString() })
    );
  },
};

const getters = {
  loadingCreatingNewService: (state) => {
    return state.price.loading || false;
  },
  createdNewServiceResponse: (state) => {
    return state.price.price;
  },

  loading(state) {
    return state.all.loading || false;
  },
  services: (state) => {
    return state.all.items || [];
  },
  loadingAffiliates: (state) => {
    return state.affiliates.loading || false;
  },
  affiliates: (state) => {
    return state.affiliates.affiliates || [];
  },
  loadingPrice: (state) => {
    return state.price.loading || false;
  },
  price: (state) => {
    return state.price.price || null;
  },
};

const mutations = {
  createNewServiceRequest(state) {
    state.price.loading = true;
  },
  createNewServiceSuccess(state, response) {
    state.price.price = response['price'];
    state.price.loading = false;
  },
  createNewServiceFailure(state, error) {
    state.price.error = error;
    state.price.loading = false;
  },
  initStore(state) {
    const services = localStorage.getItem("services");
    if (services) {
      state.all = { items: JSON.parse(services) };
    }
  },
  getAllRequest(state) {
    state.all = { loading: true };
  },
  getAllSuccess(state, services) {
    // Cache services into local storage
    localStorage.setItem("services", JSON.stringify(services));
    state.all = { items: services };
  },
  getAllFailure(state, error) {
    state.all = { error };
  },
  getByIdRequest(state) {
    state.service = { loading: true };
  },
  getByIdSuccess(state, service) {
    state.service = { service };
  },
  getByIdFailure(state, error) {
    state.service = { error };
  },
  getAffiliatesByCityIdRequest(state) {
    state.affiliates = { loading: true };
  },
  getAffiliatesByCityIdSuccess(state, affiliates) {
    state.affiliates = { loading: false, affiliates: affiliates };
  },
  getAffiliatesByCityIdFailure(state, error) {
    state.affiliates = { loading: false, error: error };
  },
  getPriceByServiceAndAffiliateRequest(state) {
    state.price = { loading: true };
  },
  getPriceByServiceAndAffiliateSuccess(state, price) {
    state.price = { loading: false, price: price };
  },
  getPriceByServiceAndAffiliateFailure(state, error) {
    state.price = { loading: false, error: error };
  },
  resetAffiliates(state) {
    state.affiliates = { loading: false, affiliates: [] };
  },
  resetPrice(state) {
    state.price = { loading: false, price: null };
  },
  deleteRequest(state, id) {
    // add 'deleting:true' property to service being deleted
    state.all.items = state.all.items.map((service) =>
      service.id === id ? { ...service, deleting: true } : service
    );
  },
  deleteSuccess(state, id) {
    // remove deleted service from state
    state.all.items = state.all.items.filter((service) => service.id !== id);
  },
  deleteFailure(state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to service
    state.all.items = state.items.map((service) => {
      if (service.id === id) {
        // make copy of service without 'deleting:true' property
        // eslint-disable-next-line no-unused-vars
        const { deleting, ...serviceCopy } = service;
        // return copy of service with 'deleteError:[error]' property
        return { ...serviceCopy, deleteError: error };
      }

      return service;
    });
  },
};

export const services = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
