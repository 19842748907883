import { familyService } from "@/services/family.service";

const state = {
  all: { loading: false },
  service: { loading: false },
  affiliates: { loading: false, affiliates: [] },
  price: { loading: false, price: null },
};

const actions = {
  getAll({ commit }) {
    commit("getAllRequest");

    familyService.getAll().then(
      (services) => commit("getAllSuccess", services),
      (error) => commit("getAllFailure", error)
    );
  },

  getById({ commit }, id) {
    commit("getByIdRequest", id);

    familyService.getById(id).then(
      (service) => commit("getByIdSuccess", service),
      (error) => commit("getByIdFailure", { id, error: error.toString() })
    );
  },
};

const getters = {
  loading(state) {
    return state.all.loading || false;
  },
  families: (state) => {
    return state.all.items || [];
  }
};

const mutations = {
  initStore(state) {
    const family = localStorage.getItem("family");
    if (family) {
      state.all = { items: JSON.parse(family) };
    }
  },
  getAllRequest(state) {
    state.all = { loading: true };
  },
  getAllSuccess(state, family) {
    // Cache services into local storage
    localStorage.setItem("family", JSON.stringify(family));
    state.all = { items: family };
  },
  getAllFailure(state, error) {
    state.all = { error };
  },
  getByIdRequest(state) {
    state.family = { loading: true };
  },
  getByIdSuccess(state, service) {
    state.family = { service };
  },
  getByIdFailure(state, error) {
    state.family = { error };
  },
};

export const families = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
