import { authHeader } from "@/helpers";
import { endpoints } from "./endpoints";

export const cartService = {
  get,
  addItem,
  updateItem,
  delete: _delete,
};

function get() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${endpoints.apiUrl}${endpoints.cartUrl}`, requestOptions).then(
    handleResponse
  );
}

function addItem(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ product: data.service.id, quantity: data.quantity }),
  };

  return fetch(`${endpoints.apiUrl}${endpoints.cartUrl}`, requestOptions).then(
    handleResponse
  );
}

function updateItem(cartItemId, quantity) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id: cartItemId, quantity: quantity }),
  };

  return fetch(`${endpoints.apiUrl}${endpoints.cartUrl}`, requestOptions).then(
    handleResponse
  );
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(cartItemId) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id: cartItemId }),
  };

  return fetch(`${endpoints.apiUrl}${endpoints.cartUrl}`, requestOptions).then(
    handleResponse
  );
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload();
      }

      const error =
        data && data.non_field_errors
          ? data.non_field_errors.toString()
          : response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
