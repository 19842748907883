import router from "@/router";

const state = {};

const actions = {
  addItem({ dispatch, commit }, { cartId, item }) {
    // Clear previous alert, if any
    dispatch("alert/clear", null, { root: true });
    commit("addItemRequest", { cartId, item });
  },
  removeItem({ dispatch, commit }, { cartId, index }) {
    // Clear previous alert, if any
    dispatch("alert/clear", null, { root: true });
    commit("removeItemRequest", { cartId, index });
  },

  confirmOrder({ dispatch, commit }, { cartId }) {
    // Clear previous alert, if any
    dispatch("alert/clear", null, { root: true });
    commit("confirmOrderRequest", { cartId });
    // commit("confirmOrderSuccess", { amount: amount });
    // Redirect to payment
    router.push({
      name: "payment",
      params: { cartId: cartId },
    });
  },
};

const mutations = {
  addItemRequest(state, { cartId, item }) {
    let cart = localStorage.getItem(cartId);
    if (cart) {
      cart = JSON.parse(cart);
      if (!cart.items) {
        cart.items = [];
      }
    } else {
      cart = { items: [] };
    }
    cart.items.push(item);
    localStorage.setItem(cartId, JSON.stringify(cart));
    state.cartId = cartId;
  },
  removeItemRequest(state, { cartId, index }) {
    let cart = JSON.parse(localStorage.getItem(cartId));
    if (cart.items) {
      cart.items.splice(index, 1);
    }
    localStorage.setItem(cartId, JSON.stringify(cart));
    state.cartId = cartId;
  },
  confirmOrderRequest(state, { cartId }) {
    let cart = JSON.parse(localStorage.getItem(cartId));
    cart.amount = cart.items.reduce((acc, item) => acc + item.price, 0);
    localStorage.setItem(cartId, JSON.stringify(cart));
    state.cartId = cartId;
  },
};

const getters = {
  // eslint-disable-next-line no-unused-vars
  cart: (_state) => (cartId) => {
    return JSON.parse(localStorage.getItem(cartId));
  },
};

export const order = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
