<template>
  <b-navbar toggleable="lg" type="light" sticky class="bg-white">
    <!-- <b-navbar-brand :to="{ name: 'home' }"> -->
      <b-navbar-brand :to="{ name: goToHome()}">
      <img
        src="@/assets/images/logo.png"
        :alt="$t('app_name')"
        height="66px"
        width="auto"
        class="d-inline-block align-top"
      />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <!-- Left aligned nav items -->
    <b-collapse id="nav-collapse" is-nav>
      <!-- <b-navbar-nav>
        <b-nav-form>
          <b-form-input
            size="sm"
            class="mr-sm-2"
            :placeholder="$t('search')"
          ></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit"
            >{{ $t("search") }}
          </b-button>
        </b-nav-form>
      </b-navbar-nav> -->

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item :to="{ name: 'about' }" :active="$route.name === 'about'"
          >{{ $t("menu.about") }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'Condizioni Generali' }" :active="$route.name === 'Condizioni Generali'"
          >{{ $t("menu.Condizioni Generali") }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'tech-fast' }" :active="$route.name === 'tech-fast'"
          >{{ $t("menu.tech_fast") }}
        </b-nav-item>
        <b-nav-item
          v-if="$isLogged() && $loggedUser().is_affiliato"
          :to="{ name: 'services' }"
          :active="$route.name === 'services'"
          >{{ $t("menu.services") }}
        </b-nav-item>
        <!-- <b-nav-item
          v-if="$isLogged() && $loggedUser().is_famiglia"
          :to="{ name: 'request_service' }"
          :active="$route.name === 'request_service'"
          >{{ $t("menu.request_service") }}
        </b-nav-item> -->
        <b-nav-item
          v-if="$isLogged() && $loggedUser().is_affiliato"
          :to="{ name: 'request_service_affiliate' }"
          :active="$route.name === 'request_service_affiliate'"
          >{{ $t("menu.request_service_affiliate") }}
        </b-nav-item>
        <b-nav-item
          v-if="$isLogged()"
          :to="{ name: 'service_requests' }"
          :active="
            $route.name === 'service_requests' || $route.name === 'payment'
          "
          >{{ $t("menu.service_requests") }}
        </b-nav-item>
        <!-- <b-nav-item
          v-if="$isLogged() && $loggedUser().is_affiliato"
          :to="{ name: 'register' }"
          :active="$route.name === 'register'"
          >{{ $t("menu.register") }}
        </b-nav-item> -->
        <b-nav-item
          v-if="!$isLogged()"
          :to="{ name: 'login' }"
          :active="$route.name === 'login'"
          >{{ $t("menu.login") }}
        </b-nav-item>
      <!--  <b-nav-item href="/prodottiAR.html" n:attr="active => $itemsParent->isActive()">
              Prodotti AR
            </b-nav-item>
-->
       <b-nav-item
        :to="{ name: 'prodottiar' }" :active="$route.name === 'prodottiar'"
          >{{ $t("menu.prodottiar") }}
        </b-nav-item>


        <b-nav-item-dropdown
          v-if="i18n_enabled"
          :text="$t('menu.language')"
          right
        >
          <b-dropdown-item
            :disabled="lang === $i18n.locale"
            @click="changeLocale(lang)"
            v-for="(lang, i) in languages"
            :key="`lang-${i}`"
            :value="lang"
          >
            {{ lang.toUpperCase() }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown v-if="$loggedUser()" right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em v-if="$loggedUser()">{{
              $loggedUser().first_name + " " + $loggedUser().last_name
            }}</em>
          </template>
          <b-dropdown-item :to="{ name: 'profile' }">{{
            $t("menu.profile")
          }}</b-dropdown-item>
          <b-dropdown-item
            v-if="$isLogged() && $loggedUser().is_affiliato"
            :to="{ name: 'manage_services' }"
            >{{ $t("menu.manage_services") }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="$isLogged() && $loggedUser().is_affiliato"
            :to="{ name: 'register' }"
            >{{ $t("menu.register") }}</b-dropdown-item
          >
          <b-dropdown-item @click="this.logout">{{
            $t("menu.logout")
          }}</b-dropdown-item>
        </b-nav-item-dropdown>

        <!-- <b-button
          v-if="this.$isLogged()"
          :to="{ name: 'cart' }"
          variant="success"
          size="sm"
          class="mb-2 mt-1 ml-2"
        >
          <b-icon-cart></b-icon-cart>{{ $t("menu.cart") }}
        </b-button> -->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import i18n from "@/i18n";
import { mapActions } from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
      user: this.$isLogged() ? this.$loggedUser() : null,
      languages: ["it", "en"],
      i18n_enabled: null,
    };
  },
  methods: {
    ...mapActions("account", ["logout"]),
    changeLocale(locale) {
      i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
    goToHome(){
      return "home"
      // if(this.user!= null){
      //   if(this.user.is_affiliato){
      //       return "home"
      //   }
      //   if(this.user.is_famiglia){
      //       return "service_requests"
      //   }
      // }
    }
  },
  mounted() {
    this.i18n_enabled = process.env.VUE_APP_I18N_ENABLED === "true";
  },
};
</script>
