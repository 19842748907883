import { authHeader } from "@/helpers";
import { endpoints } from "./endpoints";

export const marginiService = {
  getServiceMarginiServizi,
  updateMarg,
};

//#####################roba mia################################
function getServiceMarginiServizi() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  var user = JSON.parse(localStorage.getItem("user"));
  const endpoint = endpoints.requestMarginiServiziUrl; 

  return fetch(
    `${endpoints.apiUrl}${endpoint.replace("{id}", user.id)}`,
    requestOptions
  )
    .then(handleResponse)
    .then((data) => {
      console.log("Questi sono i dati ricevuti")
      console.log(data); // Visualizza i dati ottenuti dalla chiamata API
      return data; // Restituisci i dati alla chiamata del tuo modulo Vuex
    });
}
function updateMarg(serviceRequest) {
  console.log("servizi ingresso");
  console.log(serviceRequest);

  // Retrieve the user data from localStorage
  var user = JSON.parse(localStorage.getItem("user"));
  if (!user || !user.id) {
    console.error('User data not found in localStorage');
    // You might want to handle this error more gracefully
    return Promise.reject('User not logged in');
  }

  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(serviceRequest),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.updateMarginiServiziUrl.replace("{id}", user.id)}`,
    requestOptions
  ).then(handleResponse);
}

// function updateMarg(serviceRequest) {
//   console.log("servizi ingressop");
//   console.log(serviceRequest);
//   const requestOptions = {
//     method: "PUT",
//     headers: { ...authHeader(), "Content-Type": "application/json" },
//     body: JSON.stringify(serviceRequest),
//   };

//   return fetch(
//     `${
//       endpoints.apiUrl
//     }${endpoints.updateMarginiServiziUrl.replace(
//       "{id}",
//       user.id//cambiare con valore utente
//     )}`,
//     requestOptions
//   ).then(handleResponse);
// }
//#####################fine roba mia################################

function logout() {
  // remove user from local storage to log user  m faij nu bucchin strunz out
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");
}

function handleResponse(response) {
  console.log("resp");
  console.log(response);
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload();
      }

      const error =
        data && data.non_field_errors
          ? data.non_field_errors.toString()
          : response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
