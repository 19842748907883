var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "div",
        { staticClass: "p-0 m-0 container-fluid" },
        [
          _c("Navbar", { key: this.$isLogged() }),
          _c(
            "div",
            { staticClass: "content" },
            [
              _vm.alert.message
                ? _c(
                    "b-alert",
                    {
                      staticClass: "m-2",
                      attrs: {
                        variant: _vm.alert.type,
                        show: true,
                        dismissible: "",
                        fade: ""
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
                  )
                : _vm._e(),
              _c("router-view")
            ],
            1
          )
        ],
        1
      ),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }