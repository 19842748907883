import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const publicPages = ["home", "about", "services", "login", "prodottiar", "tech-fast","Condizioni Generali"];
const noAuthPages = ["login"];

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "@/pages/Home.vue"),
  },
  {
    path: "/tech-fast",
    name: "tech-fast",
    component: () => import(/* webpackChunkName: "techFast" */ "@/pages/Tech-Fast.vue"),
  },
  {
    path: "/Condizioni Generali",
    name: "Condizioni Generali",
    component: () =>
      import(/* webpackChunkName: "Condizioni Generali" */ "@/pages/CondizioniGenerali.vue"),
  },
  {
    path: "/chi-siamo",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/pages/About.vue"),
  },
  {
    path: "/servizi",
    name: "services",
    component: () =>
      import(/* webpackChunkName: "services" */ "@/pages/Services.vue"),
  },
  {
    path: "/richiedi-servizio",
    name: "request_service",
    component: () =>
      import(
        /* webpackChunkName: "service_request" */ "@/pages/ServiceRequest.vue"
      ),
    props: true,
  },
  {
    path: "/richiedi-servizio-affiliato",
    name: "request_service_affiliate",
    component: () =>
      import(
        /* webpackChunkName: "service_request" */ "@/pages/ServiceRequestAffiliate.vue"
      ),
    props: true,
  },
  {
    path: "/richieste-servizi",
    name: "service_requests",
    component: () =>
      import(
        /* webpackChunkName: "services" */ "@/pages/ServiceRequestsList.vue"
      ),
  },
  {
    path: "/richieste-servizi/:id",
    name: "service_request_detail",
    component: () =>
      import(
        /* webpackChunkName: "service_detail" */ "@/pages/ServiceRequestDetail.vue"
      ),
    props: true,
  },

  {
    path: "/richieste-margini/:id",
    name: "service_margini_detail",
    component: () =>
      import(
        /* webpackChunkName: "service_detail" */ "@/pages/ManageServices.vue"
      ),
    props: true,
  },

  {
    path: "/question/:id",
    name: "question",
    component: () =>
      import(/* webpackChunkName: "question" */ "@/pages/Question.vue"),
    props: true,
  },
  // {
  //   path: "/servizi/:id",
  //   name: "service_detail",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "service_detail" */ "@/pages/ServiceDetail.vue"
  //     ),
  //   props: true,
  // },
  // {
  //   path: "/servizi/:id/prenota",
  //   name: "service_book",
  //   component: () =>
  //     import(/* webpackChunkName: "service_book" */ "@/pages/ServiceBook.vue"),
  //   props: true,
  // },
  {
    path: "/registrati",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/pages/Register.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/pages/Login.vue"),
    props: true,
  },
  {
    path: "/prodottiar",
    name: "prodottiar",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/pages/Prodottiar.vue"),
    props: true,
  },
  {
    path: "/profilo",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/pages/Profile.vue"),
  },
  {
    path: "/gestisci-servizi",
    name: "manage_services",
    component: () =>
      import(/* webpackChunkName: "services" */ "@/pages/ManageServices.vue"),
  },
  // {
  //   path: "/carrello",
  //   name: "cart",
  //   component: () => import(/* webpackChunkName: "cart" */ "@/pages/Cart.vue"),
  // },
  {
    path: "/pagamento/:id",
    name: "payment",
    component: () =>
      import(/* webpackChunkName: "payment" */ "@/pages/Payment.vue"),
    props: true,
  },  
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "*" */ "@/pages/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    // Redirect to login page if not logged in and trying to access a restricted page
    return next({
      name: "login",
      query: { redirect: to.name },
      params: {
        message:
          "Per poter richiedere i servizi è necessario effettuare l'accesso o registrarsi",
      },
    });
  }
  if (loggedIn) {
    console.log(loggedIn);
    const onlyNoAuth = noAuthPages.includes(to.name);
    if (onlyNoAuth) {
      // Redirect to home page if logged in and trying to access login/register page
      return next({ name: "home" });
    }
  }

  next();
});

export default router;
