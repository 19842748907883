import Vue from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
import "@/assets/css/styles.scss";
import {
  BootstrapVue,
  BootstrapVueIcons,
  CarouselPlugin,
  FormCheckboxPlugin,
  NavbarPlugin,
} from "bootstrap-vue";
import i18n from "@/i18n";
import VueMeta from "vue-meta";
import Vuelidate from "vuelidate";
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

import { endpoints } from "@/services/endpoints";
import { configureFakeBackend } from "@/helpers";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(CarouselPlugin);
Vue.use(NavbarPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(FormCheckboxPlugin);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(Vuelidate);
Vue.component("v-select", vSelect);
Vue.component("multiselect", Multiselect);
Vue.component("vue-timepicker", VueTimepicker);

if (endpoints.mock) {
  // Setup fake backend
  configureFakeBackend();
}

Vue.prototype.$isLogged = () => {
  return localStorage.getItem("user") !== null;
};

Vue.prototype.$loggedUser = () => {
  return localStorage.getItem("user") !== null
    ? JSON.parse(localStorage.getItem("user"))
    : null;
};

Vue.prototype.$isOver18 = function isOver18(dateOfBirth) {
  // find the date 18 years ago
  const date18YrsAgo = new Date();
  date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
  // check if the date of birth is before that date
  return dateOfBirth <= date18YrsAgo;
};

new Vue({
  router,
  store,
  i18n,
  beforeCreate: () => {
    // Cache cities and services
    store.commit("cities/initStore");
    store.commit("services/initStore");
  },
  render: (h) => h(App),
}).$mount("#app");
