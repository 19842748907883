// array in local storage for registered users
import { endpoints } from "@/services/endpoints";
import i18n from "@/i18n";

let users = JSON.parse(localStorage.getItem("users")) || [];
let services = JSON.parse(localStorage.getItem("services")) || [];
let affiliates = JSON.parse(localStorage.getItem("affiliates")) || [];

function generateUsers() {
  let users = [
    {
      id: 1,
      username: "affiliate",
      email: "affiliate@test.xy",
      password: "Password00",
      first_name: "Affiliate",
      last_name: "User",
      token: "fake-jwt-token",
      is_staff: false,
      is_badante: false,
      is_famiglia: false,
      is_affiliato: true,
    },
    {
      id: 2,
      username: "caretaker",
      email: "caretaker@test.xy",
      password: "Password00",
      first_name: "Caretaker",
      last_name: "User",
      token: "fake-jwt-token",
      is_staff: false,
      is_badante: true,
      is_famiglia: false,
      is_affiliato: false,
    },
    {
      id: 3,
      username: "caregiver",
      email: "caregiver@test.xy",
      password: "Password00",
      first_name: "Caregiver",
      last_name: "User",
      token: "fake-jwt-token",
      is_staff: false,
      is_badante: false,
      is_famiglia: true,
      is_affiliato: false,
    },
  ];
  localStorage.setItem("users", JSON.stringify(users));
}

function generateServices() {
  let services = [
    {
      id: 1,
      name: "Assistenza Domiciliare",
      description:
        "Richiedi un’assistente per alcune ore, per il giorno o per la notte. Il nostro personale si occuperà delle esigenze della persona: dall’igiene, alla vestizione. Dall’alimentazione, alla compagnia e gestirà ogni esigenza con attenzione e professionalità.",
      slug: "assistenza-domiciliare",
      image: require("@/assets/images/mocks/home_slide_01.png"),
      price: 20.0,
      categoria: null,
    },
    {
      id: 2,
      name: "Assistenza Ospedaliera",
      description:
        "Se hai bisogno di assistenza per una o più notti in ospedale o in clinica, il nostro personale si prenderà cura della persona assistendola durante la notte, provvedendo alle sue necessità e allertando il personale della struttura quando opportuno.",
      slug: "assistenza-ospedaliera",
      image: require("@/assets/images/mocks/home_slide_02.png"),
      price: 40.0,
      categoria: null,
    },
    {
      id: 3,
      name: "Babysitter",
      description:
        "Il servizio di babysitter può essere richiesto per alcune ore o per l’intera giornata. Il nostro personale si occupa dei bambini assicurando che facciano i compiti, mangino, etc. e trascorrendo con loro alcune ore in armonia e svago.",
      slug: "babysitter",
      image: require("@/assets/images/mocks/home_slide_03.png"),
      price: 60.0,
      categoria: null,
    },
    {
      id: 4,
      name: "Colf",
      description:
        "Il servizio di colf può essere richiesto una tantum o con frequenze stabilite. Il nostro personale si occuperà delle faccende domestiche: pulizia, riassetto ed eventualmente di aiutare nella preparazione dei pasti.",
      slug: "colf",
      image: require("@/assets/images/mocks/home_slide_04.png"),
      price: 80.0,
      categoria: null,
    },
  ];
  localStorage.setItem("services", JSON.stringify(services));
}

function generateAffiliates() {
  let affiliates = [
    {
      id: 1,
      first_name: "Affiliate",
      last_name: "User 1",
      city_id: 1,
    },
    {
      id: 2,
      first_name: "Affiliate",
      last_name: "User 2",
      city_id: 2,
    },
    {
      id: 3,
      first_name: "Affiliate",
      last_name: "User 3",
      city_id: 3,
    },
  ];
  localStorage.setItem("affiliates", JSON.stringify(affiliates));
}

export function configureFakeBackend() {
  // Build mocked data
  generateUsers();
  generateServices();
  generateAffiliates();
  // Handle fetch operations
  let realFetch = window.fetch;
  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      // wrap in timeout to simulate server api call
      setTimeout(() => {
        console.log("URL intercepted by fake backend", url);
        // authenticate
        if (url.endsWith(endpoints.loginUrl) && opts.method === "POST") {
          // get parameters from post request
          let params = JSON.parse(opts.body.toString());

          // find if any user matches login credentials
          let filteredUsers = users.filter((user) => {
            return (
              user.username === params.username &&
              user.password === params.password
            );
          });

          if (filteredUsers.length) {
            // if login details are valid return user details and fake jwt token
            let user = filteredUsers[0];
            let responseJson = {
              access_token: "fake-jwt-token",
              refresh_token: "fake-refresh-token",
              user: {
                pk: user.id,
                username: user.username,
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                is_staff: user.is_staff,
                is_badante: user.is_badante,
                is_famiglia: user.is_famiglia,
                is_affiliato: user.is_affiliato,
              },
            };
            resolve({
              ok: true,
              text: () => Promise.resolve(JSON.stringify(responseJson)),
            });
          } else {
            // else return error
            reject(i18n.t("errors.bad_credentials"));
          }

          return;
        }

        // get users
        if (url.endsWith("/users") && opts.method === "GET") {
          // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
          if (
            opts.headers &&
            opts.headers.Authorization === "Bearer fake-jwt-token"
          ) {
            resolve({
              ok: true,
              text: () => Promise.resolve(JSON.stringify(users)),
            });
          } else {
            // return 401 not authorised if token is null or invalid
            reject(i18n.t("errors.unauthorized"));
          }

          return;
        }

        // get user by id
        if (url.match(/\/users\/\d+$/) && opts.method === "GET") {
          // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
          if (
            opts.headers &&
            opts.headers.Authorization === "Bearer fake-jwt-token"
          ) {
            // find user by id in users array
            let urlParts = url.split("/");
            let id = parseInt(urlParts[urlParts.length - 1]);
            let matchedUsers = users.filter((user) => {
              return user.id === id;
            });
            let user = matchedUsers.length ? matchedUsers[0] : null;

            // respond 200 OK with user
            resolve({ ok: true, text: () => JSON.stringify(user) });
          } else {
            // return 401 not authorised if token is null or invalid
            reject(i18n.t("errors.unauthorized"));
          }

          return;
        }

        // register user
        if (
          (url.endsWith(endpoints.registerAffiliateUrl) ||
            url.endsWith(endpoints.registerCaretakerUrl) ||
            url.endsWith(endpoints.registerCaregiverUrl)) &&
          opts.method === "POST"
        ) {
          // get new user object from post body
          let newUser = JSON.parse(opts.body.toString());

          // validation
          let duplicateUser = users.filter((user) => {
            return user.email === newUser.email;
          }).length;
          if (duplicateUser) {
            reject(i18n.t("errors.duplicate_email", [newUser.email]));
            return;
          }

          // save new user
          newUser.id = users.length
            ? Math.max(...users.map((user) => user.id)) + 1
            : 1;
          users.push(newUser);
          localStorage.setItem("users", JSON.stringify(users));

          // respond 200 OK
          resolve({ ok: true, text: () => Promise.resolve() });

          return;
        }

        // delete user
        if (url.match(/\/users\/\d+$/) && opts.method === "DELETE") {
          // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
          if (
            opts.headers &&
            opts.headers.Authorization === "Bearer fake-jwt-token"
          ) {
            // find user by id in users array
            let urlParts = url.split("/");
            let id = parseInt(urlParts[urlParts.length - 1]);
            for (let i = 0; i < users.length; i++) {
              let user = users[i];
              if (user.id === id) {
                // delete user
                users.splice(i, 1);
                localStorage.setItem("users", JSON.stringify(users));
                break;
              }
            }

            // respond 200 OK
            resolve({ ok: true, text: () => Promise.resolve() });
          } else {
            // return 401 not authorised if token is null or invalid
            reject(i18n.t("errors.unauthorized"));
          }

          return;
        }

        // get services
        if (url === endpoints.servicesUrl && opts.method === "GET") {
          // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
          if (
            opts.headers &&
            opts.headers.Authorization === "Bearer fake-jwt-token"
          ) {
            resolve({
              ok: true,
              text: () => Promise.resolve(JSON.stringify(services)),
            });
          } else {
            // return 401 not authorised if token is null or invalid
            reject(i18n.t("errors.unauthorized"));
          }

          return;
        }

        // find service by id
        if (url.match(/\/servizi\/\d+$/) && opts.method === "GET") {
          if (
            opts.headers &&
            opts.headers.Authorization === "Bearer fake-jwt-token"
          ) {
            // find service by id in services array
            let urlParts = url.split("/");
            let id = parseInt(urlParts[urlParts.length - 1]);
            let matchedServices = services.filter((service) => {
              return service.id === id;
            });
            let service = matchedServices.length ? matchedServices[0] : null;

            // respond 200 OK with service
            resolve({
              ok: true,
              text: () => Promise.resolve(JSON.stringify(service)),
            });
          } else {
            // return 401 not authorised if token is null or invalid
            reject(i18n.t("errors.unauthorized"));
          }

          return;
        }

        // find affiliate by city
        if (url.match(/\/affiliate\/\d+$/) && opts.method === "GET") {
          if (
            opts.headers &&
            opts.headers.Authorization === "Bearer fake-jwt-token"
          ) {
            // find city by id in affiliates array
            let urlParts = url.split("/");
            let id = parseInt(urlParts[urlParts.length - 1]);
            let matchedAffiliates = affiliates.filter((affiliate) => {
              return affiliate.city_id === id;
            });

            // respond 200 OK with affiliates list
            resolve({
              ok: true,
              text: () => Promise.resolve(JSON.stringify(matchedAffiliates)),
            });
          } else {
            // return 401 not authorised if token is null or invalid
            reject(i18n.t("errors.unauthorized"));
          }

          return;
        }

        // find price by service and affiliate
        if (
          url.match(/\/services\/\d+\/\d+\/price$/) &&
          opts.method === "GET"
        ) {
          if (
            opts.headers &&
            opts.headers.Authorization === "Bearer fake-jwt-token"
          ) {
            // respond 200 OK with random price
            resolve({
              ok: true,
              text: () => Promise.resolve(JSON.stringify({ price: 20 })),
            });
          } else {
            // return 401 not authorised if token is null or invalid
            reject(i18n.t("errors.unauthorized"));
          }

          return;
        }

        // pass through any requests not handled above
        realFetch(url, opts).then((response) => resolve(response));
      }, 1000);
    });
  };
}
