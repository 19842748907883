import { authHeader } from "@/helpers";
import { endpoints } from "./endpoints";

export const cityService = {
  getCities,
  getProvinces,
  getCountries,
};

function getCities() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.citiesUrl}`,
    requestOptions
  ).then(async (response) => {
    let data = await handleResponse(response);
    // Sort cities
    return data.sort((a, b) => a.comune.localeCompare(b.comune));
  });
}

function getProvinces() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.provincesUrl}`,
    requestOptions
  ).then(handleResponse);
}

function getCountries() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.countriesUrl}`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload();
      }

      const error =
        data && data.non_field_errors
          ? data.non_field_errors.toString()
          : response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
