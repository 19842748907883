import { authHeader } from "@/helpers";
import { endpoints } from "./endpoints";

export const familyService = {
  getAll,
  getById,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.familyUrl}`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${endpoints.apiUrl}${endpoints.familyUrl}${id}`,
    requestOptions
  ).then(handleResponse);
}


function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload();
      }

      const error =
        data && data.non_field_errors
          ? data.non_field_errors.toString()
          : response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
