import Vue from "vue";
import Vuex from "vuex";
import { alert } from "@/store/alert.module";
import { cities } from "@/store/cities.module";
import { users } from "@/store/users.module";
import { account } from "@/store/account.module";
import { order } from "@/store/order.module";
import { services } from "@/store/services.module";
import { serviceRequests } from "@/store/service_requests.module";
import { margini } from "@/store/marginiServizi.module";
import { cart } from "@/store/cart.module";
import { families } from "@/store/families.module";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alert,
    cities,
    families,
    users,
    account,
    order,
    services,
    serviceRequests,
    margini,
    cart,
  },
});
